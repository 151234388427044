import * as Popover from '@radix-ui/react-popover'
import { forwardRef, ForwardRefExoticComponent, ReactNode } from 'react'
import styled, { css } from 'styled-components'

import { BorderRadiuses, shadows, zIndexes } from 'styles'
import { Button, ButtonProps } from '../Button'
import { MoreVerticalIcon } from '../Icons'
import { UseTransientProps } from 'types/utility'

export interface PopoverMenuProps {
  children: ReactNode
  iconSize?: number
  stick?: boolean
  className?: string
}

export const PopoverMenu = ({ children, iconSize, stick, className }: PopoverMenuProps) => (
  <Popover.Root>
    <PopoverButton $stick={stick} className={className}>
      <MoreVerticalIcon size={iconSize} />
    </PopoverButton>
    <PopoverList>{children}</PopoverList>
  </Popover.Root>
)

const menuItemFrom = <P,>(component: ForwardRefExoticComponent<P>) => styled(component)`
  padding: 0;
  height: 20px;
`

const MenuItem = menuItemFrom(Button)

export const PopoverMenuItem = forwardRef<HTMLButtonElement, ButtonProps>(function PopoverMenuItem(props, ref) {
  return <MenuItem {...props} ref={ref} narrow />
})

export const PopoverButton = styled(Popover.Trigger)<UseTransientProps<Pick<PopoverMenuProps, 'stick'>, 'stick'>>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${BorderRadiuses.m};
  background: none;
  ${({ $stick }) =>
    $stick &&
    css`
      position: absolute;
      top: 12px;
      right: 12px;
    `}
`

export const PopoverList = styled(Popover.Content)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  border-radius: ${BorderRadiuses.s};
  background-color: ${({ theme }) => theme.colors.White};
  box-shadow: ${shadows.s};
  z-index: ${zIndexes.dropdown};
`
